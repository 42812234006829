<!-- This component is the same as the sk-dialog, but with an input selector inside. Due to a strange bundling bug, if you try to use the
sk-dialog with an input selector, the input selector will not work. The input selector will have a z-index at 2000 and will be not
visible inside the dialog (with a z-index at 2400). This is a workaround for that bug until a suitable solution is found with the
bundling of the kit. -->

<template>
	<v-dialog v-model="value" :activator="activator" :width="width" class="sk-dialog">
		<sk-card class="sk-dialog-card">
			<template #header-title>
				<slot name="header-title" />
			</template>
			<template #header-actions>
				<sk-close-icon-btn @click="close" />
			</template>
			<template #default>
				<slot name="title">
					<div v-if="hasTitle" class="sk-dialog-card-title">
						<v-icon v-if="icon" :color="level" :icon="icon" :size="iconSize" class="sk-dialog-card-title-icon" />
						<div v-if="title" class="sk-dialog-card-title-content text-h2-bold">{{ title }}</div>
						<div v-if="subtitle" class="sk-dialog-card-title-content text-h2">{{ subtitle }}</div>
					</div>
				</slot>
				<div class="sk-dialog-card-content">
					<sk-alert v-if="hasAlert" :level="level" :text="alertText" :title="alertTitle" class="sk-dialog-card-content-alert" />
					<slot />
				</div>
			</template>
			<template v-if="hasFooter" #footer>
				<slot :close="close" name="footer" />
			</template>
		</sk-card>
	</v-dialog>
</template>

<style lang="scss" scoped>
.sk-dialog {
	&-card {
		:deep(.sk-card-header-end) {
			align-self: flex-start;
		}

		:deep(.sk-card-body),
		:deep(.sk-card-footer) {
			padding: 0 var(--sk-spacing-lg) var(--sk-spacing-lg) !important;
		}

		&-title {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-bottom: var(--sk-spacing-md);

			&-icon {
				margin-bottom: calc(var(--sk-spacing-xs) / 2);
			}

			&-content {
				text-align: center;
			}
		}

		&-content {
			justify-content: center;

			&-alert + :deep(*) {
				margin-top: var(--sk-spacing-md);
			}
		}
	}
}
</style>

<script lang="ts" setup>
import { computed, useSlots } from 'vue';
import { VDialog, VIcon } from 'vuetify/components';

import { SkAlert, SkCard, SkCloseIconBtn } from '@silae/components';
import { useModelValue } from '@silae/composables';
import { UiLevel } from '@silae/types';

const props = withDefaults(
	defineProps<{
		activator?: string;
		alertText?: string;
		alertTitle?: string;
		icon?: string;
		iconSize?: string | number;
		level?: UiLevel;
		modelValue: boolean;
		subtitle?: string;
		title?: string;
		width?: string | number;
	}>(),
	{
		level: UiLevel.INFO,
		iconSize: 40,
		width: '540px'
	}
);

// define emits for auto completion
defineEmits<{
	(e: 'update:model-value', value: boolean);
}>();

const value = useModelValue(props);
const slots = useSlots();
const hasAlert = computed(() => props.alertText != null);
const hasFooter = computed(() => slots.footer != null);
const hasTitle = computed(() => slots.title != null || props.title != null || props.subtitle != null);
const close = () => (value.value = false);
</script>
